import type { AxiosInstance, AxiosRequestConfig } from 'axios';

interface IHttpService {
  get<T>(url: string, data?: object): Promise<T>;
  post<T>(url: string, data?: object | string): Promise<T>;
}

class HttpService implements IHttpService {
  private http: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.http = httpClient;
  }

  async get<T>(url: string, data?: object): Promise<T> {
    return await this.http.get(url, data);
  }

  async post<T>(url: string, data?: object | string, config?: AxiosRequestConfig): Promise<T> {
    return await this.http.post(url, data, config);
  }
}

export default HttpService;
