<script setup lang="ts">
import { computed } from 'vue';
import { type NavigationHookAfter, useRoute, useRouter } from 'vue-router';
// @TODO: replace to feature
import FooterWidget from '@/widgets/FooterWidget';
import HeaderWidget from '@/widgets/HeaderWidget';
import { useFlowStore } from '@/shared/composables/useFlowStore';
import { type ThemeColor } from '@/features/FlowRouter';
import { useCarStore, useSpecInfoStore } from '@/entities/Car';
import { useDriverStore } from '@/entities/Driver';
import { useQuoteStore } from '@/entities/quote/quote.store';
import { usePushEvent } from '@/features/PushEvent';
import { onBeforeUnmount, onMounted } from 'vue';
import { type PolicyHolder, usePolicyHolderStore } from '@/entities/PolicyHolder';
import { useRtl } from '@/shared/composables';

const emit = defineEmits(['back']);
const handleOnBack = () => {
  emit('back');
};

const flowStore = useFlowStore();
const carStore = useCarStore();
const specInfoStore = useSpecInfoStore();
const driverStore = useDriverStore();
const policyHolderStore = usePolicyHolderStore();
const quoteStore = useQuoteStore();

const route = useRoute();
const routeMeta = computed(() => route.meta);
const themeColor = computed(() => (routeMeta.value?.themeColor as ThemeColor) ?? 'light');
const showFooter = computed(() => (routeMeta.value?.withFooter as boolean) ?? true);

flowStore.$subscribe((_: unknown, state: any) => {
  localStorage.setItem('oman-car-flow', JSON.stringify(state));
});

carStore.$subscribe((_: unknown, state: any) => {
  localStorage.setItem('oman-car-vehicle', JSON.stringify(state));
});

driverStore.$subscribe((_: unknown, state: any) => {
  localStorage.setItem('oman-car-drivers', JSON.stringify(state));
});

policyHolderStore.$subscribe((_: unknown, state: PolicyHolder) => {
  localStorage.setItem('oman-car-policyholder', JSON.stringify(state));
});

quoteStore.$subscribe((_: unknown, state: any) => {
  localStorage.setItem('oman-car-quote', JSON.stringify(state));
});

specInfoStore.$subscribe((_: unknown, state: any) => {
  localStorage.setItem('oman-car-spec-info', JSON.stringify(state));
});

// TODO: refactor cache and serialization
const cacheCar = JSON.parse(localStorage.getItem('oman-car-vehicle') as any) ?? {};
const cacheFlow = JSON.parse(localStorage.getItem('oman-car-flow') as any) ?? {};
const cacheDrivers = JSON.parse(localStorage.getItem('oman-car-drivers') as any) ?? {};
const cachePolicyHolder = JSON.parse(localStorage.getItem('oman-car-policyholder') as any) ?? {};
const cacheQuote = JSON.parse(localStorage.getItem('oman-car-quote') as any) ?? {};
const cachePhoneNumber = (localStorage.getItem('car-insurance_phone') as string) ?? '';
const cacheSpecInfo = JSON.parse(localStorage.getItem('oman-car-spec-info') as string) ?? '';

// NOTE: некоторые данные из localStorage нужно приводить к нужному формату. Например, даты
const { policyDate } = cacheFlow;
if (typeof cachePolicyHolder?.dateOfBirth === 'string') {
  cachePolicyHolder.dateOfBirth = new Date(cachePolicyHolder?.dateOfBirth);
}
if (typeof policyDate === 'string') {
  cacheFlow.policyDate = new Date(policyDate);
}
if (typeof cacheCar?.insuranceEndDate === 'string') {
  cacheCar.insuranceEndDate = new Date(cacheCar?.insuranceEndDate);
}

if (cachePhoneNumber) {
  const phoneNumber = cachePhoneNumber.replace(/"/g, '');
  if (cacheDrivers.holder) {
    cacheDrivers.holder.phone = phoneNumber;
  } else {
    cacheDrivers.holder = {};
    cacheDrivers.holder.phone = phoneNumber;
  }
}

flowStore.$patch(cacheFlow);
carStore.$patch(cacheCar);
driverStore.$patch(cacheDrivers);
policyHolderStore.$patch(cachePolicyHolder);
quoteStore.$patch(cacheQuote);
specInfoStore.$patch(cacheSpecInfo);

const pushEvent = usePushEvent();
const router = useRouter();
let unregisterRouterHook = () => {};
onMounted(() => {
  const pushEventRouterHook: NavigationHookAfter = async (_, from) => {
    await pushEvent(from);
  };
  unregisterRouterHook = router.afterEach(pushEventRouterHook);
});

onBeforeUnmount(() => {
  unregisterRouterHook();
});

const { isRtl } = useRtl();
</script>

<template>
  <div class="flow-widget" :class="{ rtl: isRtl, 'flow-widget--primary': themeColor === 'dark' }">
    <div class="flow-widget__content">
      <HeaderWidget :theme-color="themeColor" @back="handleOnBack" />
      <slot />
      <FooterWidget v-if="showFooter" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'FlowWidget';
</style>
