import { createPinia } from 'pinia';

const pinia = createPinia();

pinia.use(({ store, app }) => {
  store.$route = app.config.globalProperties.$route;
  store.$router = app.config.globalProperties.$router;
  store.$i18n = app.config.globalProperties.$i18n;
});

export default pinia;
