import { MIN_AGE, MAX_AGE } from './PolicyHolder.constants';
export const isYoung = (age: number): boolean => age < MIN_AGE;
export const isOld = (age: number): boolean => age > MAX_AGE;
export class PolicyHolderDateOfBirthIsNotDefined extends Error {
  constructor() {
    super('PolicyHolder Date of birth is not defined');
  }
}
export class PolicyHolderIsYoungException extends Error {
  constructor() {
    super('Policy holder is too young');
  }
}
export class PolicyHolderIsOldException extends Error {
  constructor() {
    super('Policy holder is too old');
  }
}
