import { provide, ref } from 'vue';
import mitt from 'mitt';

import { buildFlowRouter, getProductName } from '@/features/FlowRouter';

import { getLandingPageUrl } from '@/shared/utils';
import type { FlowEvents } from '@/shared/composables';

import { flowOrderMap } from '../router';
import { PRODUCTS } from '../app.constants';
import { getLocaleFromUrl } from '../plugins/i18n';

export const useFlowController = () => {
  const productName = getProductName();
  const { locale } = getLocaleFromUrl();
  const landingUrl = getLandingPageUrl(locale);
  provide('landingUrl', landingUrl);

  const flowOrder = flowOrderMap[productName];
  const flowRouter = buildFlowRouter(productName, flowOrder, landingUrl);
  provide('flowRouter', flowRouter);
  provide('isRenew', productName === PRODUCTS.RENEWAL);

  const error = ref('');
  const loading = ref(false);

  const resolveError = () => {
    error.value = '';
  };

  const handleOnBack = async () => {
    await flowRouter.prevStep();
    resolveError();
  };

  const handleError = (errorMessage: unknown) => {
    error.value = errorMessage instanceof Error ? errorMessage.message : String(errorMessage);
    console.error(error.value);
  };

  const requestPool = new Set();

  const onLoading = async (
    request: () => Promise<any>,
    requestId = 'request',
    withLoader = true,
    withError = true,
    infinityLoader = false,
  ) => {
    const routeFrom = flowRouter.currentStep.value.path;
    requestPool.add(requestId);
    if (withLoader) loading.value = true;
    try {
      await request();
    } catch (error) {
      if (routeFrom === flowRouter.currentStep.value.path && withError) {
        handleError(error);
      }
    }

    if (infinityLoader) return;
    requestPool.delete(requestId);
    if (requestPool.size > 0) return;
    loading.value = false;
  };

  const ee = mitt<FlowEvents>();

  // TODO: refactor this after replace onLoading with event emitter
  ee.on(
    'loading',
    async ({
      request,
      requestId = 'request',
      withLoader = true,
      withError = true,
      infinityLoader = false,
    }) => await onLoading(request, requestId, withLoader, withError, infinityLoader),
  );

  provide('eventEmitter', ee);

  return {
    handleOnBack,
    error,
    resolveError,
    loading,
    onLoading,
  };
};
