import { API_FIELDS_KEYS_MAP } from './api.constants';
import type { ApiFields, ApiFieldsKeys } from './api.types';
import { getFormDataValueSerializer } from './api.helpers';

export const autoBindDecorator = <T extends object>(service: T) =>
  new Proxy(service, {
    get(target, prop, receiver) {
      const value = Reflect.get(target, prop, receiver);
      return typeof value === 'function' ? value.bind(target) : value;
    },
  });

export const serializeToFormData = (params: Partial<ApiFields>, wrapFormDataKey: boolean = true) =>
  Object.entries(params).reduce((formData, [key, value]) => {
    const mappedKey = API_FIELDS_KEYS_MAP[key as ApiFieldsKeys] ?? key;
    const fdKey = wrapFormDataKey ? `fields[${mappedKey}]` : mappedKey;
    const fdValue = getFormDataValueSerializer(key)(value);
    formData.set(fdKey, fdValue);
    return formData;
  }, new FormData());
