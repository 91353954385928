import { formateDateForSend } from '@/shared/utils';
import type { ApiFieldsValueSerializers } from '@/shared/api/api.types';

const formDataValuesSerializers: ApiFieldsValueSerializers = {
  dateOfBirth: (dateOfBirth: Date) => formateDateForSend(dateOfBirth),
  startPolicyDate: (startPolicyDate: Date) => formateDateForSend(startPolicyDate),
  policyDate: (policyDate: Date) => formateDateForSend(policyDate),
};

export const getFormDataValueSerializer = (key: string) => {
  const defaultSerializer = (rawValue: unknown) => rawValue + '';
  return formDataValuesSerializers[key as keyof ApiFieldsValueSerializers] ?? defaultSerializer;
};
