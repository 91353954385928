<script lang="ts">
export default {
  name: 'FlowCard',
};
</script>

<script setup lang="ts">
import { BaseButton } from '@qic/ui-components';
import {
  BASE_CARD_TEST_ID,
  BUTTON_CLICK_EVENT_NAME,
  SECOND_BUTTON_CLICK_EVENT_NAME,
} from './FlowCard.constants';
interface Props {
  title?: string;
  withButton?: boolean;
  buttonText?: string;
  secondButtonText?: Nullable<string>;
  dataTestId?: string;
  loading?: boolean;
  isErrorTimerLimit?: boolean;
}

interface Emits {
  (e: typeof BUTTON_CLICK_EVENT_NAME): void;
  (e: typeof SECOND_BUTTON_CLICK_EVENT_NAME): void;
}

withDefaults(defineProps<Props>(), {
  withButton: true,
  buttonText: '',
  secondButtonText: null,
  title: '',
  dataTestId: BASE_CARD_TEST_ID,
  loading: false,
  isErrorTimerLimit: false,
});

const emit = defineEmits<Emits>();

const handleButtonClick = () => {
  emit(BUTTON_CLICK_EVENT_NAME);
};
const secondHandleButtonClick = () => {
  emit(SECOND_BUTTON_CLICK_EVENT_NAME);
};
</script>

<template>
  <div class="base-card">
    <h1 v-if="title" class="base-card__title" :data-testid="dataTestId">{{ title }}</h1>
    <slot />
    <slot name="button">
      <BaseButton
        v-if="withButton"
        class="base-card__button"
        :disabled="loading"
        :class="{ button__opacity: isErrorTimerLimit }"
        @click="handleButtonClick"
      >
        {{ buttonText }}
      </BaseButton>
      <template v-if="secondButtonText">
        <div class="base-card__separate">
          <span class="base-card__separate-text">
            {{ $t('tpl_flow.car_details_checking.OR') }}
          </span>
        </div>
        <BaseButton
          :class="{ button__opacity: isErrorTimerLimit }"
          icon="none"
          secondary
          @click="secondHandleButtonClick"
        >
          {{ secondButtonText }}
        </BaseButton>
      </template>
    </slot>
    <slot name="after" />
  </div>
</template>

<style lang="scss" scoped>
@import './FlowCard.scss';
</style>
