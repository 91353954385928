import { defineStore } from 'pinia';
import { useApi } from '@/shared/composables/useApi';
import type {
  FeesInfo,
  RequestGetTotalPrice,
  ResponseDocumentList,
} from '@/shared/api/FlowService/FlowService.types';
import { TYPES_FEE } from '@/entities/quote';
import type { Quote } from '@/entities/quote';
import type { RequestSendDocument } from '@/shared/api/FlowService/FlowService.types';
export const useQuoteStore = defineStore('quote', {
  state: (): Quote => ({
    totalPrice: 0,
    additionalCovers: [],
    vat: 0,
    support: 0,
    localInsurance: 0,
  }),
  getters: {},
  actions: {
    setAdditionalCovers(covers: any) {
      this.additionalCovers = covers;
    },
    async getTotalPrice(data: RequestGetTotalPrice) {
      const response = await useApi().flowService.getTotalPrice(data);
      if (response) {
        this.totalPrice = response.totalprice;
        this.vat = response.taxAmount;
        const findPremium = (type: string) => {
          const premium: string | undefined = response?.covers?.feesInfo?.find(
            (value: FeesInfo) => value.code === type,
          )?.premium;
          return premium ? Number(premium) : 0;
        };
        this.support = findPremium(TYPES_FEE.SUPPORT_CODE);
        this.localInsurance = findPremium(TYPES_FEE.LOCAL_INSURANCE);
      }
    },
    async sendDocument(requestSendDocument: RequestSendDocument, callback: any) {
      return await useApi().flowService.sendDocument(requestSendDocument, callback);
    },

    async getDocumentList(quoteNumber: string): Promise<ResponseDocumentList[]> {
      return await useApi().flowService.getDocumentList(quoteNumber);
    },
  },
});
