<script setup lang="ts">
import { computed, inject } from 'vue';
import ProgressBar from '@/features/ProgressBar/ProgressBar.vue';
import ArrowLeftIcon from '@/shared/ui/icons/ArrowLeftIcon.vue';
import LogoIcon from '@/shared/ui/icons/LogoIcon.vue';
import LogoIconAr from '@/shared/ui/icons/LogoIconAr.vue';
import { type ThemeColor, useFlowRouter } from '@/features/FlowRouter';

interface Props {
  themeColor: ThemeColor;
}
withDefaults(defineProps<Props>(), {
  themeColor: 'light',
});

const emit = defineEmits(['back']);

const locale = inject('locale');
const landingUrl: string = inject('landingUrl') ?? '/';
const goToLanding = () => {
  window.location.href = landingUrl;
};

const flowRouter = useFlowRouter();
const { currentStep, steps: flowSteps, routeMeta } = flowRouter;
const showProgressBar = computed(() => routeMeta.value?.withProgressBar ?? flowSteps > 1);
const showBackButton = computed(() => routeMeta.value?.withBackButton ?? true);
const prevStepIndex = computed(() => currentStep.value.index - 1);
</script>

<template>
  <div class="flow__header-widget">
    <ArrowLeftIcon v-if="showBackButton" class="flow__header-widget-return" @click="emit('back')" />
    <LogoIconAr
      v-if="locale === 'ar'"
      class="flow__header-widget-logo"
      :class="[
        themeColor === 'dark'
          ? 'flow__header-widget-logo--gray'
          : 'flow__header-widget-logo--primary',
      ]"
      @click="goToLanding"
    />
    <LogoIcon
      v-else
      class="flow__header-widget-logo"
      :class="[
        themeColor === 'dark'
          ? 'flow__header-widget-logo--gray'
          : 'flow__header-widget-logo--primary',
      ]"
      @click="goToLanding"
    />
  </div>
  <div v-show="showProgressBar">
    <ProgressBar
      class="flow__header-widget-progress"
      :steps-count="flowSteps"
      :current-step-index="currentStep.index"
      :prev-step-index="prevStepIndex"
    />
  </div>
</template>
<style lang="scss" scoped>
@import 'HeaderWidget';
</style>
