import { createI18n } from 'vue-i18n';
import { en, ar } from './locales';

const messages = { en, ar };

export const getLocaleFromUrl = () => {
  const supportedLocales = Object.keys(messages);
  const [fallbackLocale] = supportedLocales;

  const [, localeFromURL] = window.location.pathname.split('/');

  const locale = supportedLocales.includes(localeFromURL) ? localeFromURL : fallbackLocale;

  return {
    locale,
    fallbackLocale,
  };
};

const { locale, fallbackLocale } = getLocaleFromUrl();

// Правила взяты из GhatGPT и референса в задаче QIC-900
function customRule(choice: number, choicesLength: number) {
  if (choicesLength === 2) {
    // 2 формы для чисел 1 и 2
    return choice === 0 ? 0 : 1;
  } else if (choicesLength === 6) {
    // 6 форм для чисел 0, 1, 2, 3-10, > 11
    if (choice === 0) {
      return 0;
    } else if (choice === 1) {
      return 1;
    } else if (choice === 2) {
      return 2;
    } else if (choice > 2 && choice <= 10) {
      return 3;
    } else {
      return 4;
    }
  }
}

export default createI18n({
  legacy: false,
  locale,
  fallbackLocale,
  pluralRules: {
    ar: customRule,
  },
  messages,
});
