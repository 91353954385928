import { type AxiosInstance, type AxiosRequestConfig, default as axios } from 'axios';
import { type RouteLocationNormalized } from 'vue-router';
import { useFlowStore } from '@/shared/composables';
import type { ApiABExperiment } from '@/shared/types/flowStore.types';
import { pushEvent } from './push-event';

export const usePushEvent = () => {
  const httpConfig: AxiosRequestConfig = {
    baseURL: import.meta.env.VUE_APP_PUBLIC_API_LEGACY_URL,
    withCredentials: true,
  };
  const http: AxiosInstance = axios.create(httpConfig);

  const flowStore = useFlowStore();

  return async (fromRoute: RouteLocationNormalized, additionalParams?: Record<string, unknown>) => {
    const referrer = `${location.origin}${fromRoute.fullPath}`;
    const experimentGroups: string[] = flowStore.ABDistribution.map(
      (experiment: ApiABExperiment) => experiment.group,
    );
    // TODO: remove undefined
    await pushEvent(http, referrer, experimentGroups, undefined, additionalParams);
  };
};
