import { defineStore } from 'pinia';

import { useApi } from '@/shared/composables';
import type { PolicyInfoCar, VehicleSpecs } from '@/shared/api/FlowService';

import {
  type Car,
  checkValidBodyCode,
  isActivePolicy,
  ActivePolicyException,
  CarCannotBeInsuredOnlineException,
} from '../lib';
import type { ApiFields } from '@/shared/api';

import { VEHICLE_USAGE_PRIVATE_CODE } from '../lib';

// TODO: refactor store
export const useCarStore = defineStore('CarEntity', {
  state: (): Car => ({
    chassisNumber: '',
    model: { name: '', code: '' },
    brand: { name: '', code: '' },
    modelYear: null,
    seats: '',
    cylinders: '',
    firstRegistrationYear: null,
    vehicleUsage: { name: '', code: '' },
    body: { name: '', code: '' }, // TODO: think about naming, original: vehicleType
    plateType: '',
    plateNumber: '',
    insuranceEndDate: null, // TODO: Policy/Quote entity
    admeId: 0,
    engineNumber: null,
    isNoPlateNumber: false,
    isPlateNotFound: null,
    // TODO: нужен для бизнес логики. Проверить можно ли упростить
    vehicleError: null,
  }),
  getters: {
    bodyCode: (state) => state.body.code,
    bodyCodeIsUnavailable: (state) => !checkValidBodyCode(state.body.code),
    usageCode: (state) => state.vehicleUsage.code,
    usageCodeIsUnavailable: (state) => state.vehicleUsage.code !== VEHICLE_USAGE_PRIVATE_CODE,
    isDataGetSpecFilled(state): boolean {
      return Boolean(state.admeId && state.brand?.code && state.modelYear && state.model?.code);
    },
  },
  actions: {
    async save(payload: Partial<ApiFields>) {
      return await useApi().flowService.sendFormData(payload);
    },

    setChassisNumber(chassisNumber: string) {
      this.chassisNumber = chassisNumber;
    },

    setModel(model: NameCode) {
      this.model = model;
    },

    setBrand(brand: NameCode) {
      this.brand = brand;
    },

    setModelYear(modelYear: Nullable<number>) {
      this.modelYear = modelYear;
    },

    setSeats(seats: string) {
      this.seats = seats;
    },

    setCylinders(cylinders: string) {
      this.cylinders = cylinders;
    },

    setBody(body: NameCode) {
      this.body = body;
    },

    setVehicleUsage(vehicleUsage: NameCode) {
      this.vehicleUsage = vehicleUsage;
    },

    setFirstRegistrationYear(firstRegistrationYear: Nullable<number>) {
      this.firstRegistrationYear = firstRegistrationYear;
    },

    setInsuranceEndDate(endDate: Date) {
      this.insuranceEndDate = endDate;
    },

    setIsNoPlateNumber(value: boolean) {
      this.isNoPlateNumber = value;
    },

    setIsPlateNotFound(value: boolean) {
      this.isPlateNotFound = value;
    },

    async getPlateTypes(): Promise<NameCode[]> {
      return await useApi().flowService.getPlateTypes();
    },

    setPlateType(plateType: string) {
      this.plateType = plateType;
    },

    setPlateNumber(plateNumber: string) {
      this.plateNumber = plateNumber;
    },

    setCarInfo(info: Car) {
      this.$patch(info);
    },

    setAdmeId(admeId: number) {
      this.admeId = admeId;
    },

    setVehicleError(vehicleError: Nullable<string>) {
      this.vehicleError = vehicleError;
    },

    async getVehicleDescriptionByPlate(token: string) {
      const carInfo = await useApi().flowService.getVehicleDescriptionByPlate({
        plateNumber: this.plateNumber,
        plateType: this.plateType,
        token,
      });
      this.setCarInfo(carInfo);
      this.setIsPlateNotFound(false);
    },

    async getModels(brandCode?: string): Promise<NameCode[]> {
      const brand = brandCode || this.brand.code;
      if (!brand) return [];
      return await useApi().flowService.getModels(brand);
    },

    async getBrands(): Promise<NameCode[]> {
      return await useApi().flowService.getBrands();
    },

    async getVehicleTypes(): Promise<NameCode[]> {
      const vehicleTypes = await useApi().flowService.getVehicleTypes();
      return vehicleTypes.filter((vehicleType) => checkValidBodyCode(vehicleType.code));
    },

    async getVehicleSpecs(): Promise<VehicleSpecs> {
      const params = {
        brand: this.brand.code,
        model: this.model.code,
        modelYear: this.modelYear,
      };
      return await useApi().flowService.getVehicleSpecs(params);
    },

    async getCarInfoByChassisNumber(token: string) {
      const carInfo: Car = await useApi().flowService.getVehicleDescriptionByChassisNumber({
        chassisNumber: this.chassisNumber,
        response_token: token,
      });
      this.setCarInfo(carInfo);
      this.setIsPlateNotFound(false);
      return carInfo;
    },

    setCarDetailsForRenew(payload: PolicyInfoCar) {
      this.$patch(payload);
    },

    checkInsurancePossibility() {
      if (this.bodyCodeIsUnavailable || this.usageCodeIsUnavailable) {
        throw new CarCannotBeInsuredOnlineException();
      }
      if (this.insuranceEndDate && isActivePolicy(this.insuranceEndDate)) {
        throw new ActivePolicyException();
      }

      return true;
    },
  },
});
