import { type NavigationFailure } from 'vue-router';
import { computed, inject, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { getLocaleFromUrl } from '@/app/plugins/i18n';
import type {
  FlowProcess,
  FlowDirection,
  FlowProcessSkipData,
  FlowRouter,
  FlowOrder,
  FlowStep,
  DoStepParams,
} from './flow-router.types';
import { useCarStore } from '@/entities/Car';
import { usePolicyHolderStore } from '@/entities/PolicyHolder';

const usePathWithLocale = (locale: string) => (path: string) => {
  let optimizedPath = path.startsWith('/') ? path.slice(1) : path;
  if (optimizedPath.endsWith('/')) optimizedPath = optimizedPath.slice(0, -1);
  return `/${locale}/${optimizedPath}`;
};

export const buildFlowRouter = (
  productName: string,
  flowOrder: FlowProcess[],
  homeUrl = '/',
): FlowRouter => {
  const appRouter = useRouter();
  const route = useRoute();
  const { locale } = getLocaleFromUrl();
  const getPathWithLocale = usePathWithLocale(locale);

  const pathHead = getPathWithLocale(`${productName}/flow`);

  const getPathByName = (routeName: string) => appRouter.resolve({ name: routeName }).path;

  const flowOrderMap = computed<FlowOrder>(
    () =>
      new Map(
        flowOrder.map((step, idx, steps) => [
          step.name,
          {
            path: getPathByName(step.name),
            prev: steps[idx - 1] ?? null,
            next: steps[idx + 1] ?? null,
            current: step,
            index: idx,
          },
        ]),
      ),
  );

  const withParams = (path: string, params?: DoStepParams['query']) => ({
    path,
    query: {
      ...route.query,
      ...params,
    },
  });

  const getFlowRouteByPath = (path: string) => {
    const maybeFlowRoutePath = path.split(`${pathHead}/`).pop();
    return flowOrderMap.value.get(maybeFlowRoutePath!);
  };

  const firstStepName = flowOrder[0].name;
  const firstStep = flowOrderMap.value.get(firstStepName)!;

  const currentStep = ref<FlowStep>(getFlowRouteByPath(route.path) ?? firstStep);
  const previousStep = ref<FlowStep | null>(null);
  const flowDirection = ref<FlowDirection>('forward');

  const checkIsSkip = (step: FlowProcess, direction: FlowDirection): boolean => {
    // TODO: coupling with business logic
    const policyHolder = usePolicyHolderStore();
    const carStore = useCarStore();

    const appState: FlowProcessSkipData = {
      phone: policyHolder.phone,
      chassisNumber: carStore.chassisNumber,
      flowDirection: direction,
    };

    if (typeof step.isSkip === 'function') {
      return step.isSkip(appState);
    }

    if (direction === 'forward' && typeof step.isForwardSkip === 'function') {
      return step.isForwardSkip(appState);
    }

    if (direction === 'backward' && typeof step.isBackwardSkip === 'function') {
      return step.isBackwardSkip(appState);
    }

    return false;
  };

  const appRouterGoTo = (
    path: string,
    query: DoStepParams['query'],
    method: 'push' | 'replace' = 'push',
  ) => appRouter[method](withParams(path, query));

  let customBackwardAction: ((...args: any[]) => any) | null = null;

  const doStep = (
    params: DoStepParams,
  ): Promise<void | NavigationFailure | undefined> | undefined => {
    const { direction, pageName, redirectURL, replace = false, query } = params;
    const directionStep = direction === 'forward' ? 'next' : 'prev';
    const nextStepProposal = currentStep.value[directionStep];
    const nextStep = flowOrderMap.value.get(pageName || nextStepProposal?.name);

    if (direction === 'backward' && customBackwardAction !== null) {
      const action = customBackwardAction;
      customBackwardAction = null;
      return action();
    }

    if (direction === 'backward' && currentStep.value.current.possibleBackwardTo) {
      const prevRouteName = previousStep.value?.current.name;
      const possibleBackwardTo = currentStep.value.current.possibleBackwardTo;
      const [fallbackName] = Array.from(possibleBackwardTo);
      const fallbackTo = flowOrderMap.value.get(fallbackName)!;

      const setPreviusStep = () => {
        previousStep.value = flowOrderMap.value.get(currentStep.value.prev.name) ?? null;
      };

      if (!prevRouteName) {
        currentStep.value = fallbackTo;
        setPreviusStep();
        return appRouterGoTo(fallbackTo.path, query);
      }

      const backwardTo = currentStep.value.current.possibleBackwardTo.has(prevRouteName);
      if (backwardTo && previousStep.value) {
        currentStep.value = previousStep.value;
        setPreviusStep();
        return appRouterGoTo(currentStep.value.path, query);
      }

      currentStep.value = fallbackTo;
      setPreviusStep();
      return appRouterGoTo(fallbackTo.path, query);
    }

    if (!nextStep) {
      if (redirectURL) {
        window.location.href = redirectURL;
      }
      return;
    }

    const isSkip = checkIsSkip(nextStepProposal, direction);
    flowDirection.value = direction;
    if (!replace) previousStep.value = currentStep.value;
    currentStep.value = nextStep;

    if (isSkip) {
      params.replace = true;
      return doStep(params);
    }

    const methodToUse = replace ? 'replace' : 'push';
    return appRouterGoTo(nextStep.path, query, methodToUse);
  };

  const nextStep = (params?: Partial<DoStepParams>): ReturnType<typeof doStep> =>
    doStep({
      ...params,
      direction: 'forward',
    });

  const prevStep = (params?: Partial<DoStepParams>): ReturnType<typeof doStep> =>
    doStep({
      ...params,
      direction: 'backward',
      redirectURL: homeUrl,
    });

  const goToFirstStep = (params?: Partial<DoStepParams>) => {
    currentStep.value = firstStep;
    flowDirection.value = 'forward';
    const isSkip = checkIsSkip(currentStep.value.current, 'forward');

    if (isSkip) {
      return nextStep(params);
    }

    const to = withParams(currentStep.value.path);
    if (params?.query) {
      to.query = {
        ...to.query,
        ...params.query,
      };
    }
    return appRouter.replace(to);
  };

  const getFirstStep = () => firstStep;
  const getFirstStepPath = () => firstStep.path;

  const routeMeta = computed(() => route.meta);

  return {
    currentStep,
    previousStep,
    flowDirection,
    routeMeta,
    nextStep,
    prevStep,
    goToFirstStep,
    getFirstStepPath,
    getFirstStep,
    get pathHead() {
      return pathHead;
    },
    get steps() {
      return flowOrderMap.value.size;
    },
    set customBackwardAction(action: ((...args: any[]) => any) | null) {
      customBackwardAction = action;
    },
    // TODO: plugins for FlowRouter
    // use(pluginFn: (context: FlowRouter) => void) {
    //   pluginFn(this);
    // },
  };
};

export const useFlowRouter = (): FlowRouter => {
  const flowRouter = inject<FlowRouter>('flowRouter');
  if (!flowRouter) {
    throw new Error('Cannot inject flow router! Init and provide before use it!');
  }
  return flowRouter;
};
