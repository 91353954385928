import { type AxiosInstance } from 'axios';

declare global {
  interface Window {
    gaGlobal: {
      from_cookie: boolean;
      hid: number;
      vid: string;
    };
  }
}

let savedParams: { path: string; url: string } | null = null;

export const pushEvent = (
  http: AxiosInstance,
  referrer: string | null,
  experimentGroups: string[],
  tries = 10,
  additionalParams?: Record<string, unknown>,
) => {
  if (!window?.gaGlobal) {
    if (tries < 0) {
      console.info(
        '%c[Push-event] `gaGlobal` not found 🤔',
        `background-color: #f94c27; color: white; font-size: 1.5rem;
         border-radius: 16px; padding: 12px 20px;`,
      );
      return;
    }
    return setTimeout(() => {
      pushEvent(http, referrer, experimentGroups, tries - 1);
    }, 500);
  }
  const clientId = window.gaGlobal.vid;
  const url = new URL(window.location.href);

  const locationParams = {
    path: window.location.pathname,
    url: window.location.href,
  };

  if (savedParams) {
    const pathSame = savedParams.path === locationParams.path;
    const urlSame = savedParams.url === locationParams.url;
    if (pathSame && urlSame) {
      return Promise.resolve();
    }
  }

  savedParams = locationParams;

  const data: Record<string, string> = {
    event_name: 'view_page',
    referrer: referrer || document.referrer,
    google_id: clientId,
    timestamp: String(Math.floor(Date.now() / 1000)),
    ...locationParams,
    ...additionalParams,
  };

  for (const utmTag of ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']) {
    data[utmTag] = url.searchParams.get(utmTag) ?? '';
  }

  const experimentGroupStr = experimentGroups.join(',');
  if (experimentGroupStr.length > 0) {
    data.experiment_group = experimentGroupStr;
  }

  const params = new URLSearchParams(data);
  return http.post('/events/?action=pushevent', params);
};
