export interface Purchase {
  policyId: string;
  transactionId: string;
  addons: number;
  plan: string;
  totalPrice: number;
  addonsPrice: number;
  listOfAddons: string[];
}

export enum TFlowType {
  MANUAL = 'manual_flow',
  RECOGNIZED_PLATE_NUMBER = 'recognized_plate_number',
  UNRECOGNIZED_PLATE_NUMBER = 'unrecognized_plate_number',
  RECOGNIZED_CHASSIS_NUMBER = 'recognized_chassis_number',
  UNRECOGNIZED_CHASSIS_NUMBER = 'unrecognized_chassis_number',
  RECOGNIZED_LICENSE = 'recognized_license_number',
  UNRECOGNIZED_LICENSE = 'unrecognized_license_number',
}
