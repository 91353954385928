import { defineStore } from 'pinia';
import type { FlowStoreType, QuoteType } from '@/shared/types/flowStore.types';

import { useApi } from './useApi';
import { regionNames } from '../constants/regionNamesMapping';

export const useFlowStore = defineStore('FlowStore', {
  state: (): FlowStoreType => ({
    startPolicy: new Date(),
    quotes: [],
    quoteNumber: null,
    geoAreaName: '',
    quoteNameCode: '',
    geoAreaCode: '',
    policyNumber: null,
    policyId: null,
    policyStatus: '',
    productCode: '',

    // AB testing
    ABDistribution: [],
  }),
  getters: {},
  actions: {
    async save(params: any) {
      await useApi().flowService.sendFormData(params);
    },

    setStartPolicyDate(date: Date) {
      this.startPolicy = date;
    },

    setPolicyId(policyId: string) {
      this.policyId = policyId;
    },

    setQuotes(quotes: QuoteType[]) {
      this.quotes = quotes;
    },

    setProductCode(productCode: string) {
      this.productCode = productCode;
    },

    async fetchQuotes(geoArea: string) {
      const quotes = await useApi().flowService.getQuotes({ geoArea });

      this.setQuotes(quotes);
    },

    async updateQuote(quoteNumber: string) {
      await useApi().flowService.updateQuote({ quoteNo: quoteNumber });
    },

    async setRenewalQuote(policyNo: Nullable<string>, transId: Nullable<string>) {
      if (policyNo === null || transId === null) {
        throw new Error("Haven't got params");
      }

      this.quotes = await useApi().flowService.getRenewalQuote(policyNo, transId);
    },

    setQuoteNumber(quoteNumber: string) {
      this.quoteNumber = quoteNumber;
    },

    setGeoAreaName(geoAreaName: string) {
      this.geoAreaName = geoAreaName;
    },

    setPolicyStatus(policyStatus: string) {
      this.policyStatus = policyStatus;
    },

    setPolicyNumber(policyNumber: Nullable<string>) {
      this.policyNumber = policyNumber;
    },

    setQuoteNameCode(code: string) {
      this.quoteNameCode = code;
    },

    setGeoArea(code: string) {
      this.geoAreaCode = code;
      this.geoAreaName = regionNames[code];
    },
  },
});
