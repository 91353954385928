import type { FlowProcess } from '@/features/FlowRouter';

import { renewFlowSteps } from '@/shared/router/flowSteps';

export const renewFlowOrder: FlowProcess[] = [
  {
    name: renewFlowSteps.RENEWAL_LOGIN,
  },
  {
    name: renewFlowSteps.POLICY_INFORMATION,
  },
  {
    name: renewFlowSteps.POLICY_HOLDER_INFORMATION,
  },
  {
    name: renewFlowSteps.INSURED_DRIVERS,
    possibleBackwardTo: new Set([
      renewFlowSteps.POLICY_INFORMATION,
      renewFlowSteps.POLICY_HOLDER_INFORMATION,
    ]),
  },
  {
    name: renewFlowSteps.QUOTE,
  },
];
