<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import {
  ANIMATION_DELAY_MS,
  DEFAULT_DARK_WIDTH,
  DEFAULT_LIGHT_WIDTH,
  ONE_HUNDRED_PERCENT,
  DEFAULT_FLOW_CURRENT_STEP,
  DEFAULT_FLOW_FIRST_STEP,
  DEFAULT_FLOW_STEPS_COUNT,
} from './ProgressBar.constants';
import { calculatePercentage } from './ProgressBar.utils';

interface Props {
  stepsCount: number;
  currentStepIndex: number;
  prevStepIndex: number;
}

const props = withDefaults(defineProps<Props>(), {
  stepsCount: DEFAULT_FLOW_STEPS_COUNT,
  currentStepIndex: DEFAULT_FLOW_CURRENT_STEP,
  prevStepIndex: DEFAULT_FLOW_FIRST_STEP,
});

const darkWidth = ref(DEFAULT_DARK_WIDTH);
const lightWidth = ref(DEFAULT_LIGHT_WIDTH);
const stepWidth = ONE_HUNDRED_PERCENT / props.stepsCount;

const currentStep = computed(() => {
  const step = props.currentStepIndex + 1;
  return step > props.stepsCount ? props.stepsCount : step;
});
const prevStep = computed(() => {
  const step = props.prevStepIndex + 1;
  return step < 0 ? 0 : step;
});

const setProgressForStep = () => {
  darkWidth.value = calculatePercentage(prevStep.value, stepWidth);
  lightWidth.value = calculatePercentage(currentStep.value, stepWidth);
};

const updateProgress = () => setTimeout(setProgressForStep, ANIMATION_DELAY_MS);

watch(() => props.currentStepIndex, updateProgress);

onMounted(() => {
  updateProgress();
});
</script>

<template>
  <div class="flow-progress">
    <div class="flow-progress__scale">
      <div class="flow-progress__light" :style="{ width: lightWidth }" />
      <div class="flow-progress__dark" :style="{ width: darkWidth }" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'ProgressBar';
</style>
