import { apiBuilder } from '@/shared/api';

let api: ReturnType<typeof apiBuilder> | null = null;
export const useApi = () => {
  if (api) return api;
  api = apiBuilder({
    baseURL: import.meta.env.VUE_APP_PUBLIC_API_URL,
    withCredentials: true,
  });
  return api;
};
