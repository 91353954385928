<script setup lang="ts">
import FlowWidget from '@/widgets/FlowWidget';
import NetworkErrorWidget from '@/widgets/NetworkErrorWidget';
import LoaderWidget from '@/widgets/LoaderWidget';
import { useFlowController } from './flow';

const { handleOnBack, error, resolveError, loading, onLoading } = useFlowController();
</script>

<template>
  <FlowWidget @back="handleOnBack">
    <NetworkErrorWidget v-if="error" :message="error" @resolve="resolveError" />
    <template v-else>
      <LoaderWidget v-show="loading" />
      <RouterView v-show="!loading" @load="onLoading" />
    </template>
  </FlowWidget>
</template>
