// NOTE: названия шагов должны быть уникальны независимо для flow

export const carFlowSteps = {
  PHONE: 'phone',
  PLATE_NUMBER: 'plate-number',
  CHASSIS_NUMBER: 'chassis-number',
  CAR_DETAILS: 'car-details',
  VEHICLE_CHECK: 'vehicle-check',
  START_POLICY_DATE: 'start-policy-date',
  SELECT_PLAN: 'select-plan',
  COVERAGE_AREA: 'select-coverage-area',
  POLICY_HOLDER_INFORMATION: 'policyholder',
  INSURED_DRIVERS: 'insured-drivers',
  QUOTE: 'quote',
  UPLOAD_DOCUMENTS: 'upload-documents',
  REDIRECT: 'redirect',
  INSURANCE_RESULT: 'insurance-result',
  CHECK_DOCUMENTS: 'check-documents',

  ACTIVE_POLICY_ERROR: 'active-policy',
  CANNOT_INSURE_ONLINE_ERROR: 'cannot-insure-online',
  ONLY_OFFLINE: 'only-offline',
};

export const renewFlowSteps = {
  RENEWAL_LOGIN: 'renewal-login',
  POLICY_INFORMATION: 'policy-information-renewal',
  POLICY_HOLDER_INFORMATION: 'policyholder-renewal',
  INSURED_DRIVERS: 'insured-drivers-renewal',
  QUOTE: 'quote-renewal',
  REDIRECT: 'redirect-renewal',
  INSURANCE_RESULT: 'insurance-result-renewal',

  RENEWED_POLICY_ERROR: 'renewed-policy',
};
