import { ACTIVE_VEHICLE_TYPES } from './Car.constants';
// TODO: fix foreign dependency
import type { CarDescription } from '@/shared/api/FlowService';

export const checkValidBodyCode = (code: string) => ACTIVE_VEHICLE_TYPES.includes(code);

export const getUniqueListOf = (cars: CarDescription[], carProperty: keyof CarDescription) => {
  const uniqueValues = new Set();
  for (const car of cars) {
    uniqueValues.add(car[carProperty]);
  }
  return Array.from(uniqueValues) as (typeof carProperty)[];
};

const now = new Date();
export const DATE_FOR_ACTIVE_POLICY = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate() + 30,
);

export const isActivePolicy = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate()) > DATE_FOR_ACTIVE_POLICY;

export class CarCannotBeInsuredOnlineException extends Error {
  constructor() {
    super('Car cannot be insured online');
  }
}

// TODO: move to Policy entity
export class ActivePolicyException extends Error {
  constructor() {
    super('Active policy');
  }
}
