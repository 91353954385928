<!-- eslint-disable max-len -->
<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5877 4.901C18.6676 3.97921 17.5722 3.2478 16.3649 2.74965C15.1575 2.25148 13.8625 1.99661 12.555 2.00003L12.5497 2.00005C7.0599 2.00004 2.60215 6.44855 2.60048 11.8926C2.59964 13.637 3.05673 15.3366 3.92507 16.8334L4.34592 17.5588L3.3473 21.1895L7.09329 20.2113L7.78766 20.5878C9.2469 21.3791 10.8828 21.794 12.5457 21.794C12.5457 21.794 12.5457 21.794 12.5457 21.794H12.5507C18.0404 21.794 22.4983 17.3456 22.4999 11.9004L22.4999 11.8948C22.504 10.5978 22.2496 9.3127 21.7512 8.11367C21.2529 6.91463 20.5203 5.82519 19.5954 4.90863L19.5877 4.901ZM12.5457 23.794C10.5502 23.794 8.5866 23.2961 6.83434 22.346L0.5 24L2.19512 17.837C1.14911 16.034 0.599476 13.988 0.600481 11.892C0.602491 5.33503 5.96417 4.09775e-05 12.5497 4.09775e-05C14.1206 -0.00407624 15.6766 0.302104 17.1277 0.90084C18.5788 1.49958 19.8961 2.37896 21.0032 3.48804C22.1159 4.5907 22.9978 5.902 23.5981 7.34606C24.1983 8.79013 24.5048 10.3383 24.4999 11.901C24.4979 18.459 19.1363 23.794 12.5507 23.794H12.5457Z"
      fill="#F1F4F6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9987 14.382C17.6993 14.233 16.2312 13.515 15.9579 13.415C15.6846 13.316 15.4857 13.267 15.2857 13.565C15.0867 13.861 14.515 14.531 14.3412 14.729C14.1663 14.928 13.9925 14.952 13.6941 14.804C13.3956 14.654 12.433 14.341 11.2926 13.329C10.4053 12.541 9.80545 11.568 9.63162 11.27C9.45778 10.973 9.61253 10.812 9.76224 10.664C9.89689 10.531 10.0607 10.317 10.2104 10.144C10.3601 9.97002 10.4093 9.84602 10.5088 9.64702C10.6093 9.44902 10.5591 9.27602 10.4837 9.12702C10.4093 8.97802 9.81248 7.51502 9.56329 6.92002C9.32113 6.34102 9.07495 6.42002 8.89208 6.41002C8.71724 6.40202 8.51829 6.40002 8.31933 6.40002C8.12038 6.40002 7.79683 6.47402 7.52352 6.77202C7.24921 7.06902 6.47852 7.78802 6.47852 9.25102C6.47852 10.713 7.54764 12.126 7.69735 12.325C7.84707 12.523 9.80244 15.525 12.7978 16.812C13.5112 17.118 14.0669 17.301 14.4999 17.437C15.2154 17.664 15.8665 17.632 16.3809 17.555C16.9537 17.47 18.1474 16.836 18.3966 16.142C18.6448 15.448 18.6448 14.853 18.5704 14.729C18.4961 14.605 18.2971 14.531 17.9977 14.382H17.9987Z"
      fill="#F1F4F6"
    />
  </svg>
</template>
