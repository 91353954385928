import { defineStore } from 'pinia';

import { useApi } from '@/shared/composables/useApi';
import type { ApiFields } from '@/shared/api/api.types';
import type { LicenseInfo, PolicyHolderInfo } from '@/shared/api/FlowService';
import { calculateAge } from '@/shared/helpers';

import {
  type PolicyHolder,
  isOld,
  isYoung,
  PolicyHolderIsYoungException,
  PolicyHolderIsOldException,
  PolicyHolderDateOfBirthIsNotDefined,
} from '../lib';

export const usePolicyHolderStore = defineStore('PolicyHolderEntity', {
  state: (): PolicyHolder => ({
    dateOfBirth: null,
    drivingExperienceOman: null,
    email: null,
    familyName: null,
    firstName: null,
    isDateOfBirthEditable: true,
    isDrivingExpEditable: true,
    isLicenseCorrect: null,
    licenseNumber: null,
    licenseErrorName: null,
    phone: '',
    srNo: 1,
  }),
  getters: {
    transId: (state) => {
      if (!state.licenseNumber) {
        return null;
      }

      return state.licenseNumber.substring(state.licenseNumber.length - 4);
    },
    getDrivingExperienceOman: (state) =>
      state.drivingExperienceOman?.toString() ? state.drivingExperienceOman.toString() : null,
  },
  // TODO: Nullable on arguments
  // TODO: save method - deprecate?
  actions: {
    // TODO: params type
    async save(payload: Partial<ApiFields>) {
      await useApi().flowService.sendFormData(payload);
    },
    async getLicenseInfo(license: string): Promise<LicenseInfo> {
      return useApi().flowService.getLicenseInfo(license);
    },

    // TODO: rename when getLicenseInfo will not need
    async getLicenseInfo2(license: string) {
      try {
        this.setLicenseErrorName(null);

        const licenseInfo = await useApi().flowService.getLicenseInfo(license);
        const exp = licenseInfo.drivingExperienceOman;
        const experienceExist = exp !== null && exp >= 0;
        const dateOfBirth = licenseInfo.dateOfBirth;

        this.setLicenseNumber(licenseInfo.licenseNumber);
        this.setIsLicenseCorrect(!!licenseInfo.dateOfBirth);

        if (dateOfBirth) {
          this.setDateOfBirth(licenseInfo.dateOfBirth);
          this.setIsDateOfBirthEditable(false);
        } else {
          this.setDateOfBirth(null);
          this.setIsDateOfBirthEditable(true);
        }

        if (experienceExist) {
          this.setDrivingExperienceOman(exp);
          this.setIsDrivingExpEditable(false);
        } else {
          this.setDrivingExperienceOman(null);
          this.setIsDrivingExpEditable(true);
        }
      } catch (e) {
        this.setLicenseErrorName(e as string);
        this.resetLicenseInfo();
      }
    },

    setLicenseNumber(licenseNumber: Nullable<string>) {
      this.licenseNumber = licenseNumber;
    },

    setDrivingExperienceOman(drivingExperienceOman: Nullable<number>) {
      this.drivingExperienceOman = drivingExperienceOman;
    },

    setFirstName(firstName: Nullable<string>) {
      this.firstName = firstName;
    },

    setFamilyName(familyName: Nullable<string>) {
      this.familyName = familyName;
    },

    setEmail(email: Nullable<string>) {
      this.email = email;
    },
    setIsDrivingExpEditable(value: boolean) {
      this.isDrivingExpEditable = value;
    },

    setPhone(phone: string) {
      this.phone = phone ?? '';
    },

    setDateOfBirth(dateOfBirth: Nullable<Date>) {
      this.dateOfBirth = dateOfBirth;
    },
    setLicenseErrorName(value: Nullable<string>) {
      this.licenseErrorName = value;
    },

    setIsDateOfBirthEditable(value: boolean) {
      this.isDateOfBirthEditable = value;
    },

    setInformationForRenew(payload: PolicyHolderInfo) {
      this.$patch(payload);
    },

    setIsLicenseCorrect(payload: Nullable<boolean>) {
      this.isLicenseCorrect = payload;
    },

    resetLicenseInfo() {
      this.setDateOfBirth(null);
      this.setDrivingExperienceOman(null);
      this.setIsDateOfBirthEditable(true);
      this.setIsDrivingExpEditable(true);
      this.setIsLicenseCorrect(false);
    },

    checkPolicyHolderAge() {
      if (!this.dateOfBirth) throw new PolicyHolderDateOfBirthIsNotDefined();
      const age = calculateAge(this.dateOfBirth);
      if (isYoung(age)) throw new PolicyHolderIsYoungException();
      if (isOld(age)) throw new PolicyHolderIsOldException();
      return true;
    },
  },
});
