<template>
  <RouterView />
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { RouteLocationNormalized } from 'vue-router';
import { useRouter } from 'vue-router';

const calcTitle = ref<string>('');
const calcDescription = ref<string>('');
const { t } = useI18n();
const router = useRouter();

onMounted(() => {
  calcTitle.value = t('meta.title');
  calcDescription.value = t('meta.description');
});

watch(
  () => router.currentRoute.value,
  (to: RouteLocationNormalized) => {
    document.title = calcTitle.value;
    const meta = document.getElementsByTagName('meta');

    for (let i = 0; i < meta.length; i++) {
      if (meta[i].name.toLowerCase() === 'description') {
        meta[i].content = calcDescription.value;
      }
    }
  },
  { immediate: true },
);
</script>
