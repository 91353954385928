import { type App } from 'vue';

interface recaptchaOptions {
  siteKey: string;
}

const recaptcha = {
  install(app: App, options: recaptchaOptions) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.google.com/recaptcha/api.js?render=${options.siteKey}`;
    script.async = true;
    script.defer = true;

    document.head.appendChild(script);
  },
};

export default recaptcha;
