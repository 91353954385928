export const calculateAge = (birthdate: Date): number => {
  const now = new Date();

  const currentYear: number = now.getFullYear();
  const currentMonth: number = now.getMonth();
  const currentDay: number = now.getDate();

  const birthYear: number = birthdate.getFullYear();
  const birthMonth: number = birthdate.getMonth();
  const birthDay: number = birthdate.getDate();

  let age: number = currentYear - birthYear;

  if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
    age = age - 1;
  }

  return age;
};
