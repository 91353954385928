<script lang="ts" setup>
import MessageIcon from '@/shared/ui/icons/MessageIcon.vue';

interface Emits {
  (e: 'click'): void;
}

const emit = defineEmits<Emits>();
</script>

<template>
  <button class="need-help-button" @click="emit('click')">
    <MessageIcon class="need-help-button__icon" />
    <span class="need-help-button__title">{{ $t('help_popup.open') }}</span>
  </button>
</template>

<style lang="scss" scoped>
@import 'NeedHelpButton';
</style>

<style lang="scss">
@import '@styles/mixins.scss';
@import '@styles/theme.scss';

.need-help-button__icon {
  path {
    @include transition;
    stroke: transparentize($text-color-middle, 0.2);
  }
}

.need-help-button:hover {
  .need-help-button__icon {
    path {
      stroke: $text-color-middle;
    }
  }
}
</style>
